import { RootStackScreenProps } from "../types";
import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Pressable,
  TextInput,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  Platform,
} from "react-native";

import moment from "moment";

import useIsMobile from "../hooks/useIsMobile";
import Heights from "../constants/Heights";
import Sizes from "../constants/Sizes";
import CommonStyles from "../constants/CommonStyles";
import { MyInformation } from "../components/MyInformation";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { useEffect, useState } from "react";
import { activationService } from "../services/ActivationService";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import {
  getBillingPeriod,
  getFormattedDate,
  getTimeDifferenceInDays,
  getTimeFromPlanTypeD,
  mapBoxOnePrepaidPlans,
} from "../utilities/utilities";
import { DateTimePickerAndroid } from "@react-native-community/datetimepicker";
import {
  DatePickerModal,
  registerTranslation,
  enGB,
} from "react-native-paper-dates";
import { AppHorizontalBar } from "../components/AppHorizontalBar";

import { ParsedPlan } from "../types/models/ParsedPlan";
import Colors from "../constants/Colors";
import FontFamilys from "../constants/FontFamilys";
import { StepType } from "../types/enums/StepType";
import TextStyles from "../constants/TextStyles";
import { AppTextInput } from "../components/AppTextInput";
import { AppGap } from "../components/AppGap";
import { provinces } from "../constants/Constants";
import { carriers } from "../constants/Carriers";
import { Picker } from "@react-native-picker/picker";
import { AppButton } from "../components/AppButton";
import { MiniLoading } from "../components/MiniLoading";
import Checkbox from "expo-checkbox";
import FlexStyles from "../constants/FlexStyles";
import { GetTotalPrepaidResponse } from "../types/responses/GetTotalPrepaidResponse";
import { AppModal } from "../components/AppModal";
import { AppFooter } from "../components/AppFooter";

type SimType = "esim" | "regular";
type TermCategory = "cp" | "ot";

export default function ActivationScreen({
  navigation,
}: RootStackScreenProps<"Activation">) {
  const marginHorizontal = useHorizontalContainerMargin();
  const isMobile = useIsMobile();
  registerTranslation("en", enGB);

  const { userInfo } = useSelector((state: RootState) => state.user);

  // need for MyAccount API requests
  const boxOneHeader = {
    headers: {
      aaid: userInfo.aaid,
      token: userInfo.token,
    },
  };

  const [selectedPlan, setSelectedPlan] = useState<ParsedPlan | undefined>(
    undefined
  );
  const [step, setStep] = useState<StepType>(StepType.First);
  const inputLabelColor = isMobile ? Colors.white : Colors.black;
  const [loading, setLoading] = useState(false);
  // loading for initial screen load
  const [initialLoading, setInitialLoading] = useState(false);
  // loading for activate button
  const [activateLoading, setActivateLoading] = useState(false);

  const [error, setError] = useState("");
  const [simError, setSimError] = useState("");
  const [activationError, setActivationError] = useState("");
  const [isEsim, setIsEsim] = useState(false);
  const [partnerPromocode, setPartnerPromocode] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  // sim CP or One Term for plan lists
  const [termCategory, setTermCategory] = useState<TermCategory>("cp");
  const [otPlans, setOtPlans] = useState<ParsedPlan[]>([]);
  const [cpPlans, setCpPlans] = useState<ParsedPlan[]>([]);
  const plans = termCategory === "cp" ? cpPlans : otPlans;

  const [isValidSimCard, setIsValidSimCard] = useState(false);

  const hasOtPlans = otPlans.length > 0;
  const hasCpPlans = cpPlans.length > 0;

  const [simNum, setSimNum] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");

  // portin related
  const [isPortin, setIsPortin] = useState(false);
  const [carrier, setCarrier] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const isFreedomPortin = carrier === "Freedom";
  const accountNumberLabel = isFreedomPortin
    ? "IMEI Number (15 digits)"
    : "Account Number";

  // activation date chosing:
  const now = new Date();
  const [date, setDate] = useState(new Date(now));

  const invalidPortCarriers = ["Telus", "Koodo", "Public Mobile"];

  const activateDisabled =
    !firstName ||
    !lastName ||
    !email ||
    !city ||
    !province ||
    (isPortin &&
      (carrier === "" ||
        invalidPortCarriers.indexOf(carrier) !== -1 ||
        accountNumber === "" ||
        phoneNumber === ""));

  // billing end date chosing:
  // billing end date defaults as current date + 1
  // billing end date can be no later than 30 days after start date.
  // max billing end date also corresponds to the service end date  (formatted end date)
  // inclusive end date, n - 1, e.g. 30 is 29

  /**
   * billingPeriod for prepaid and onetime plans does not matter,
   * I guess back end changed the logic again or
   * I was not aware of it as I was not the one who implemented it
   */
  let billingPeriod = selectedPlan
    ? selectedPlan.isOt
      ? +selectedPlan.planTypeD
      : getBillingPeriod(date)
    : getBillingPeriod(new Date());

  const _tempInitialDate = moment(date)
    .add(billingPeriod - 1, "days")
    .toDate(); // Inclusive 30 // Default is 30 days
  const [maxBillingEndDate, setMaxBillingEndDate] = useState(_tempInitialDate);
  const [billingEndDate, setBillingEndDate] = useState(_tempInitialDate);

  useEffect(() => {
    const _tempDate = moment(date)
      .add(billingPeriod - 1, "days")
      .toDate(); // Inclusive 30 // Default is 30 days
    if (selectedPlan?.isOt) {
      setBillingEndDate(_tempDate);
    }
    setMaxBillingEndDate(_tempDate);
  }, [selectedPlan]);
  // formatted dates
  const formattedStart = getFormattedDate(date);
  const formattedEnd = getFormattedDate(maxBillingEndDate);
  const formattedBillend = getFormattedDate(billingEndDate);

  // modal related
  const [showModal, setShowModal] = useState(false);
  const [showBillingModal, setShowBillingModal] = useState(false);

  const [acceptCorrectInformation, setAcceptCorrectInformation] =
    useState(false);

  const isAndroid = Platform.OS === "android";

  const [getTotalPrepaidResponse, setGetTotalPrepaidResponse] = useState<
    GetTotalPrepaidResponse | undefined
  >(undefined);
  const hasPrepaidTotal = getTotalPrepaidResponse && getTotalPrepaidResponse[0];

  // formatting data for display & postActivationInfoPrepaid
  const prepaidTotalStrings = {
    prorateDuration: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].prorate_Duration
      : "",
    chargeDuration: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].charge_Duration
      : "",
    prorateAmt: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].prorate_Amt.toFixed(2)
      : "0.00",
    gstAmt: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].gst_Amt.toFixed(2)
      : "0.00",
    gstRate: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].gst_rate.toFixed(2)
      : "0.0",
    pstAmt: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].pst_Amt.toFixed(2)
      : "0.00",
    pstRate: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].pst_rate.toFixed(2)
      : "0.0",
    totalTaxAmt: hasPrepaidTotal
      ? (
          getTotalPrepaidResponse[0].pst_Amt +
          getTotalPrepaidResponse[0].gst_Amt
        ).toFixed(2)
      : "0.00",
    totalTaxRate: hasPrepaidTotal
      ? (
          getTotalPrepaidResponse[0].pst_rate +
          getTotalPrepaidResponse[0].gst_rate
        ).toFixed(2)
      : "0.0",
    subtotal: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].subtotal.toFixed(2)
      : "0.00",
    total: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].total.toFixed(2)
      : "0.00",
    planId: selectedPlan ? selectedPlan.planId.toString() : "",
    simcard_fee: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].simcard_amt.toFixed(2)
      : "",
    promoAmt: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].promo_Amt.toFixed(2)
      : "",
    planAmt: hasPrepaidTotal
      ? getTotalPrepaidResponse[0].plan_Amt.toFixed(2)
      : "",
  };

  // ref # as response from postActivationInfoPrepaid
  const [activationRefNumber, setActivationRefNumber] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    const getPromocodeAndPlansAsync = async () => {
      setInitialLoading(true);

      try {
        // get partner's promocode
        const getPromocodeResponse =
          await activationService.getPartnerPromocode({
            data: {
              bizid: userInfo.bid,
              countryId: 42, // always Canada
            },
          });

        if (!getPromocodeResponse || !getPromocodeResponse.data) {
          throw new Error("Something went wrong with getPartnerPromocode()");
        }

        // set promocodes and headers
        const promocode = getPromocodeResponse.data;
        setPartnerPromocode(promocode);

        // get OT and CP plans in one Promise.all
        const [otPlansResponse, cpPlansResponse] = await Promise.all([
          activationService.getPrepaidPlans(
            {
              data: {
                term: "ot",
                promocode: promocode,
              },
            },
            boxOneHeader
          ),
          activationService.getPrepaidPlans(
            {
              data: {
                term: "cp",
                promocode: promocode,
              },
            },
            boxOneHeader
          ),
        ]);

        if (otPlansResponse?.data?.length > 0) {
          setOtPlans(mapBoxOnePrepaidPlans(otPlansResponse.data, true));
        }

        if (cpPlansResponse?.data?.length > 0) {
          setCpPlans(mapBoxOnePrepaidPlans(cpPlansResponse.data));
        } else {
          // change default category if no CP plans are found.
          setTermCategory("ot");
        }
      } catch (error) {
        if (error instanceof Error) {
          setSimError(error.message);
        }
        console.error(error);
      } finally {
        setInitialLoading(false);
      }
    };

    getPromocodeAndPlansAsync();
  }, []);

  useEffect(() => {
    const getTotalPrepaidAsync = async () => {
      if (!selectedPlan) {
        return;
      }

      if (!province) {
        return;
      }

      setActivateLoading(true);
      try {
        const prepaidTotalResponse = await activationService.getTotalPrepaid(
          {
            data: {
              orderSimcard: true, // always true, but fee will be $0
              PlanId: selectedPlan.planId,
              CountryId: 42, // only Canada
              ProvinceCode: province,
              start: formattedStart,
              end: formattedEnd,
              promocode: partnerPromocode,
              billend: formattedBillend,
              term: termCategory,
              esim: isEsim,
            },
          },
          boxOneHeader
        );

        if (!prepaidTotalResponse || !prepaidTotalResponse.data) {
          throw new Error("Sorry, something went wrong.");
        }

        if (prepaidTotalResponse && prepaidTotalResponse.data) {
          setGetTotalPrepaidResponse(prepaidTotalResponse.data);
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
        console.error(error);
      } finally {
        setActivateLoading(false);
      }
    };

    getTotalPrepaidAsync();
  }, [selectedPlan, billingEndDate, province]);

  const onPressVerify = async () => {
    if (!simNum) {
      setSimError("Please enter a SIM card number.");
      return;
    }

    setLoading(true);
    setSimError("");
    let simCardNumber = simNum.trim();

    try {
      const verifySimCardResponse = await activationService.verifySimCard(
        {
          data: {
            simNum: simCardNumber,
          },
        },
        boxOneHeader
      );

      if (!verifySimCardResponse || !verifySimCardResponse.data) {
        throw new Error("The SIM Card number you entered is incorrect.");
      }

      if (verifySimCardResponse?.data?.Type === "Postpaid") {
        // can only be Prepaid
        throw new Error("Sorry, this SIM Card number cannot be used.");
      }

      // don't think I need the following info, waiting for testing to succeed;

      // let promocode;
      // let carrier;

      // if (verifySimCardResponse?.data?.PromoCode) {
      //   promocode = verifySimCardResponse.data.PromoCode;
      // }

      // if (verifySimCardResponse?.data?.Carrier) {
      //   carrier = verifySimCardResponse.data.Carrier;
      // }

      setIsValidSimCard(true);
    } catch (error) {
      if (error instanceof Error) {
        setSimError(error.message);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePressEnter = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    if (e.nativeEvent.key === "Enter") {
      onPressVerify();
    }
  };

  const onPressSim = (plan: ParsedPlan, simType: SimType) => {
    setSelectedPlan(plan);
    setStep(StepType.Second);
    setIsEsim(simType === "esim");
  };

  const onPressBack = () => {
    setStep(StepType.First);
  };

  const onPressShow = (type: "start" | "billing-end") => {
    // web and ios use a separate plugin for date-picking.
    // react-native-paper-dates has poor compatibility with Android
    // docs: https://web-ridge.github.io/react-native-paper-dates/docs/date-picker/single-date-picker
    // android: https://github.com/react-native-datetimepicker/datetimepicker
    if (!isAndroid) {
      if (type === "start") {
        setShowModal(true);
      } else if (type === "billing-end") {
        setShowBillingModal(true);
      }
    } else {
      if (type === "start") {
        DateTimePickerAndroid.open({
          value: date,
          onChange,
          mode: "date",
          is24Hour: true,
          minimumDate: now,
        });
      } else {
        DateTimePickerAndroid.open({
          value: billingEndDate,
          onChange,
          mode: "date",
          is24Hour: true,
          minimumDate: date,
        });
      }
    }
  };

  const onChange = (event: any, selectedDate: any) => {
    updateDates(selectedDate);
  };

  const onDismissDate = (type: "start" | "billing-end") => {
    if (type === "billing-end") {
      setShowBillingModal(false);
    } else if (type === "start") {
      setShowModal(false);
    }
  };

  const onConfirmDate = (params: any) => {
    setShowModal(false);
    updateDates(params.date);
  };

  const updateDates = (date: Date) => {
    setDate(date);
    if (termCategory === "cp") {
      billingPeriod = getBillingPeriod(date);
    }
    const newEndDate = moment(date)
      .add(billingPeriod - 1, "days")
      .toDate();
    setBillingEndDate(newEndDate); // -1 as Date is Inclusive
    setMaxBillingEndDate(newEndDate);
  };

  const onConfirmBillingEndDate = (params: any) => {
    setShowBillingModal(false);
    setBillingEndDate(params.date);
  };

  const onPressActivate = async () => {
    setActivateLoading(true);
    try {
      const postActivationResponse =
        await activationService.postActivationInfoPrepaid(
          {
            data: {
              payload: {
                esim: isEsim,
                over18: false,
                simcardOrder: true, // should be true, fee should be $0
                simcard_no: simNum,
                service_countryId: "42", // always canada
                service_province: province,
                service_city: city,
                delivery_province: "",
                delivery_address: "",
                delivery_postal: "",
                startDate: formattedStart,
                endDate: formattedEnd,
                planId: prepaidTotalStrings.planId,
                firstName: firstName,
                lastName: lastName,
                email: email,
                simcard_fee: prepaidTotalStrings.simcard_fee,
                charge_duration: prepaidTotalStrings.chargeDuration,
                currency: "CAD", // always Canada
                plan_fee: prepaidTotalStrings.planAmt,
                gst_rate: prepaidTotalStrings.gstRate,
                gst_amt: prepaidTotalStrings.gstAmt,
                pst_rate: prepaidTotalStrings.pstRate,
                pst_amt: prepaidTotalStrings.pstAmt,
                promocode: partnerPromocode,
                promocredit: prepaidTotalStrings.promoAmt,
                subtotal: prepaidTotalStrings.subtotal,
                total: prepaidTotalStrings.total,
                bizId: userInfo.bid,
                memo: "",
                portin_carrier: carrier,
                portin_accountNo: accountNumber,
                portin_phoneNo: phoneNumber,
                portin_other: "",
                billend:
                  selectedPlan?.isOt || termCategory === "ot"
                    ? null
                    : formattedBillend,
                prorate_fee: prepaidTotalStrings.prorateAmt,
                prorate_duration: prepaidTotalStrings.prorateDuration,
              },
              queryString: {
                term: termCategory,
              },
            },
          },
          boxOneHeader
        );

      if (!postActivationResponse) {
        throw new Error(
          "Sorry, something went wrong with generating your order."
        );
      }

      // 0 response from backend means failure from our API
      if (postActivationResponse?.data === 0) {
        throw new Error("Sorry, we had trouble generating your order.");
      }

      if (postActivationResponse?.data) {
        setActivationRefNumber(postActivationResponse.data);
        setStep(StepType.Success);
      }
    } catch (error) {
      if (error instanceof Error) {
        setActivationError(error.message);
      }
      console.error(error);
    } finally {
      setActivateLoading(false);
    }
  };

  const onPressNext = () => {
    if (!selectedPlan) {
      return;
    }

    if (!isEsim && (!simNum || !isValidSimCard)) {
      setError("You must provide a valid SIM card number.");
      return;
    }

    if (isPortin) {
      if (!phoneNumber) {
        setError("You must provide a phone number if you want to port in.");
        return;
      } else if (!accountNumber) {
        setError(
          `You must provide an ${
            isFreedomPortin ? "IMEI" : "account"
          } number if you want to port in.`
        );
        return;
      }
    }

    setError("");
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    if (StepType.Success) {
      setStep(StepType.First);
    }
    setModalVisible(false);
  };

  const PlanTag = (props: { category: TermCategory }) => {
    const isSelected = props.category === termCategory;

    const tagStyle = {
      ...styles.planTag,
      backgroundColor: isSelected ? Colors.blue : Colors.white,
    };
    const tagTextStyle = {
      ...styles.planTagText,
      color: isSelected ? Colors.white : Colors.blue,
    };

    const onPressTag = () => {
      setTermCategory(props.category);
    };

    return (
      <Pressable style={tagStyle} onPress={onPressTag}>
        <Text style={tagTextStyle}>
          {props.category === "cp" ? "SIM CP" : "One Term"}
        </Text>
      </Pressable>
    );
  };
  const PlanSelectCard = (props: { plan: ParsedPlan }) => {
    const time = getTimeFromPlanTypeD(props.plan.planTypeD, props.plan.isOt);

    return (
      <View style={styles.planSelectCard}>
        <View style={CommonStyles.justifyRow}>
          <View style={CommonStyles.row}>
            <Text style={styles.dollarSignLabel}>$</Text>
            <Text style={styles.moneyLabel}>{props.plan.plan_fee}</Text>
            <View style={{ padding: 4 }}>
              <Text style={styles.currencyLabel}>CAD</Text>
              <Text style={styles.timeLabel}>{time}</Text>
            </View>
          </View>
          <Text style={styles.dataLabel}>{props.plan.data}</Text>
        </View>
        <View style={CommonStyles.justifyRow}>
          <Pressable
            style={styles.regularSimButton}
            onPress={() => onPressSim(props.plan, "regular")}
          >
            <Text style={styles.regularSimText}>Regular SIM</Text>
          </Pressable>
          <Pressable
            style={styles.eSimButton}
            onPress={() => onPressSim(props.plan, "esim")}
          >
            <Text style={styles.eSimText}>eSIM</Text>
          </Pressable>
          <View />
        </View>
      </View>
    );
  };

  return (
    <>
      {!isMobile && (
        <>
          <ScrollView
            style={{
              paddingBottom: Heights.scrollViewPaddingBottom,
              paddingTop: Heights.mainPagePaddingTop,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                marginHorizontal: marginHorizontal,
              }}
            >
              <View style={{ flex: 1 }}>
                <View style={{ marginTop: Sizes.large, marginBottom: 40 }}>
                  <MyInformation />
                </View>
                {initialLoading ? (
                  <MiniLoading />
                ) : (
                  <>
                    {step === StepType.First && (
                      <>
                        <View style={{ ...CommonStyles.row, marginLeft: 40 }}>
                          {hasCpPlans && <PlanTag category="cp" />}
                          {hasOtPlans && <PlanTag category="ot" />}
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            marginHorizontal: 40,
                          }}
                        >
                          {plans.map((plan: ParsedPlan, index: number) => (
                            <PlanSelectCard key={index} plan={plan} />
                          ))}
                        </View>
                      </>
                    )}
                    {step === StepType.Second && (
                      <>
                        <Pressable onPress={onPressBack} style={{ width: 70 }}>
                          <Text style={TextStyles.semiSubtitle}>
                            {"<< Back"}
                          </Text>
                        </Pressable>
                        <AppGap direction="vertical" size={Sizes.large} />
                        <View style={CommonStyles.topRow}>
                          <View style={{ flex: 1 }}>
                            {!isEsim && (
                              <>
                                <View
                                  style={{
                                    ...CommonStyles.justifyRow,
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <View style={styles.inputContainer}>
                                    <Text
                                      style={{
                                        ...styles.inputLabel,
                                        color: inputLabelColor,
                                      }}
                                    >
                                      Input your SIM Card number:
                                    </Text>
                                    <AppTextInput
                                      value={simNum}
                                      maxLength={22}
                                      onChangeText={(text) => setSimNum(text)}
                                      placeholder="SIM #"
                                      onKeyPress={handlePressEnter}
                                    />
                                  </View>
                                  <AppGap
                                    direction="horizontal"
                                    size={Sizes.large}
                                  />
                                  <AppButton
                                    text="Verify"
                                    size="sm"
                                    onPress={onPressVerify}
                                    loading={loading}
                                  />
                                </View>
                                {simError.length > 0 && (
                                  <Text
                                    style={{
                                      color: Colors.red,
                                      marginTop: Sizes.medium,
                                      textAlign: "center",
                                    }}
                                  >
                                    {simError}
                                  </Text>
                                )}
                                {isValidSimCard && (
                                  <Text
                                    style={{
                                      color: Colors.successGreen,
                                      marginVertical: Sizes.medium,
                                      textAlign: "center",
                                    }}
                                  >
                                    The SIM card number you entered is verified.
                                  </Text>
                                )}
                                <AppGap
                                  direction="vertical"
                                  size={Sizes.large}
                                />
                              </>
                            )}
                            <View style={CommonStyles.justifyRow}>
                              <View style={styles.inputContainer}>
                                <Text
                                  style={{
                                    ...styles.inputLabel,
                                    color: inputLabelColor,
                                  }}
                                >
                                  First Name
                                </Text>
                                <AppTextInput
                                  value={firstName}
                                  onChangeText={(text) => setFirstName(text)}
                                />
                              </View>
                              <AppGap
                                direction="horizontal"
                                size={Sizes.large}
                              />
                              <View style={styles.inputContainer}>
                                <Text
                                  style={{
                                    ...styles.inputLabel,
                                    color: inputLabelColor,
                                  }}
                                >
                                  Last Name
                                </Text>
                                <AppTextInput
                                  value={lastName}
                                  onChangeText={(text) => setLastName(text)}
                                />
                              </View>
                            </View>
                            <AppGap direction="vertical" size={Sizes.medium} />
                            <View>
                              <Text
                                style={{
                                  ...styles.inputLabel,
                                  color: inputLabelColor,
                                }}
                              >
                                Email
                              </Text>
                              <AppTextInput
                                value={email}
                                onChangeText={(text) => setEmail(text)}
                              />
                            </View>
                            <AppGap direction="vertical" size={Sizes.medium} />
                            <View style={CommonStyles.justifyRow}>
                              <View style={styles.inputContainer}>
                                <Text
                                  style={{
                                    ...styles.inputLabel,
                                    color: inputLabelColor,
                                  }}
                                >
                                  Province
                                </Text>
                                <Picker
                                  style={styles.dropdown}
                                  onValueChange={(itemValue: string) =>
                                    setProvince(itemValue)
                                  }
                                  selectedValue={province}
                                >
                                  <Picker.Item
                                    key={99}
                                    label="Select a Province"
                                    value=""
                                    enabled={false}
                                  ></Picker.Item>
                                  {provinces.map((province, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={province.name}
                                        value={province.code}
                                      />
                                    );
                                  })}
                                </Picker>
                              </View>
                              <AppGap
                                direction="horizontal"
                                size={Sizes.large}
                              />
                              <View style={styles.inputContainer}>
                                <Text
                                  style={{
                                    ...styles.inputLabel,
                                    color: inputLabelColor,
                                  }}
                                >
                                  City
                                </Text>
                                <AppTextInput
                                  value={city}
                                  onChangeText={(text) => setCity(text)}
                                />
                              </View>
                            </View>
                            <AppGap direction="vertical" size={Sizes.large} />
                            <AppHorizontalBar
                              size={300}
                              color={Colors.lightGrey}
                            />
                            <AppGap direction="vertical" size={Sizes.large} />
                            <View style={styles.inputContainer}>
                              <Text
                                style={{
                                  ...styles.inputLabel,
                                  color: inputLabelColor,
                                }}
                              >
                                Do you want to port in your phone number?
                              </Text>
                              <View style={CommonStyles.row}>
                                <View style={CommonStyles.row}>
                                  <Checkbox
                                    value={isPortin}
                                    onValueChange={() => setIsPortin(true)}
                                  />
                                  <Text style={{ paddingLeft: Sizes.small }}>
                                    Yes
                                  </Text>
                                </View>
                                <AppGap
                                  direction="horizontal"
                                  size={Sizes.medium}
                                />
                                <View style={CommonStyles.row}>
                                  <Checkbox
                                    value={!isPortin}
                                    onValueChange={() => setIsPortin(false)}
                                  />
                                  <Text style={{ paddingLeft: Sizes.small }}>
                                    No
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {isPortin && (
                              <>
                                <View
                                  style={[
                                    CommonStyles.justifyRow,
                                    {
                                      alignItems: "flex-start",
                                      marginTop: Sizes.large,
                                    },
                                  ]}
                                >
                                  <View style={styles.inputContainer}>
                                    <Text
                                      style={{
                                        ...styles.inputLabel,
                                        color: inputLabelColor,
                                      }}
                                    >
                                      Carrier
                                    </Text>
                                    <Picker
                                      style={styles.dropdown}
                                      onValueChange={(itemValue: string) =>
                                        setCarrier(itemValue)
                                      }
                                      selectedValue={carrier}
                                    >
                                      <Picker.Item
                                        key={99}
                                        label="Select a Carrier"
                                        value=""
                                      ></Picker.Item>
                                      {carriers.map((carrier, index) => {
                                        return (
                                          <Picker.Item
                                            key={index}
                                            label={carrier.name}
                                            value={carrier.name}
                                          />
                                        );
                                      })}
                                    </Picker>
                                    {invalidPortCarriers.indexOf(carrier) !==
                                    -1 ? (
                                      <Text
                                        style={{
                                          color: Colors.red,
                                          paddingTop: 6,
                                          paddingLeft: 20,
                                        }}
                                      >
                                        Sorry, we are unable to port from{" "}
                                        {carrier}.
                                      </Text>
                                    ) : null}
                                  </View>
                                  <AppGap
                                    direction="horizontal"
                                    size={Sizes.large}
                                  />
                                  <View style={styles.inputContainer}>
                                    <Text
                                      style={{
                                        ...styles.inputLabel,
                                        color: inputLabelColor,
                                      }}
                                    >
                                      {accountNumberLabel}
                                    </Text>
                                    <AppTextInput
                                      editable={
                                        invalidPortCarriers.indexOf(carrier) ===
                                        -1
                                      }
                                      value={accountNumber}
                                      onChangeText={(text) =>
                                        setAccountNumber(text)
                                      }
                                    />
                                  </View>
                                </View>
                                <AppGap
                                  direction="vertical"
                                  size={Sizes.medium}
                                />
                                <View style={CommonStyles.justifyRow}>
                                  <View style={styles.inputContainer}>
                                    <Text
                                      style={{
                                        ...styles.inputLabel,
                                        color: inputLabelColor,
                                      }}
                                    >
                                      Phone Number
                                    </Text>
                                    <AppTextInput
                                      editable={
                                        invalidPortCarriers.indexOf(carrier) ===
                                        -1
                                      }
                                      value={phoneNumber}
                                      onChangeText={(text) =>
                                        setPhoneNumber(text)
                                      }
                                    />
                                  </View>
                                  <AppGap
                                    direction="horizontal"
                                    size={Sizes.large}
                                  />
                                  <View style={styles.inputContainer} />
                                </View>
                              </>
                            )}
                            <AppGap direction="vertical" size={Sizes.large} />
                            <AppHorizontalBar
                              size={300}
                              color={Colors.lightGrey}
                            />
                            <AppGap direction="vertical" size={Sizes.large} />
                            {termCategory === "cp" && (
                              <>
                                <Text
                                  style={{
                                    ...styles.inputLabel,
                                    color: inputLabelColor,
                                  }}
                                >
                                  Starting Date
                                </Text>
                                <View style={styles.datePickerContainer}>
                                  <Pressable
                                    onPress={() => onPressShow("start")}
                                  >
                                    <Text style={styles.disabledTextInput}>
                                      {date.toLocaleDateString()}
                                    </Text>
                                  </Pressable>
                                  <DatePickerModal
                                    validRange={{
                                      startDate: now,
                                    }}
                                    locale="en"
                                    mode={"single"}
                                    visible={showModal && !isAndroid}
                                    date={date}
                                    onConfirm={onConfirmDate}
                                    onDismiss={() => onDismissDate("start")}
                                  />
                                </View>
                                <Text
                                  style={{
                                    ...styles.inputLabel,
                                    color: inputLabelColor,
                                  }}
                                >
                                  Billing Period End Date
                                </Text>
                                <View style={styles.datePickerContainer}>
                                  <Pressable
                                    onPress={() => onPressShow("billing-end")}
                                  >
                                    <Text style={styles.disabledTextInput}>
                                      {billingEndDate.toLocaleDateString()}
                                    </Text>
                                  </Pressable>
                                  <DatePickerModal
                                    validRange={{
                                      startDate: date,
                                      endDate: maxBillingEndDate,
                                    }}
                                    locale="en"
                                    mode={"single"}
                                    visible={showBillingModal && !isAndroid}
                                    date={billingEndDate}
                                    onConfirm={onConfirmBillingEndDate}
                                    onDismiss={() =>
                                      onDismissDate("billing-end")
                                    }
                                  />
                                </View>
                              </>
                            )}
                            {termCategory === "ot" && (
                              <>
                                <Text
                                  style={{
                                    ...styles.inputLabel,
                                    color: inputLabelColor,
                                  }}
                                >
                                  Activation Date
                                </Text>
                                <View style={styles.datePickerContainer}>
                                  <Pressable
                                    onPress={() => onPressShow("start")}
                                  >
                                    <Text style={styles.disabledTextInput}>
                                      {date.toLocaleDateString()}
                                    </Text>
                                  </Pressable>
                                  <DatePickerModal
                                    validRange={{
                                      startDate: now,
                                    }}
                                    locale="en"
                                    mode={"single"}
                                    visible={showModal && !isAndroid}
                                    date={date}
                                    onConfirm={onConfirmDate}
                                    onDismiss={() => onDismissDate("start")}
                                  />
                                </View>
                              </>
                            )}
                            {error.length > 0 && (
                              <Text
                                style={{
                                  color: Colors.red,
                                  marginTop: Sizes.large,
                                  textAlign: "center",
                                }}
                              >
                                {error}
                              </Text>
                            )}
                            <AppGap size={Sizes.large} direction="vertical" />
                            <View style={FlexStyles.rowCenter}>
                              <AppButton
                                text="Next"
                                onPress={onPressNext}
                                loading={activateLoading}
                                disabled={activateDisabled}
                              />
                            </View>
                            <AppGap size={200} direction="vertical" />
                          </View>
                          <AppGap size={Sizes.large} />
                          <View style={styles.costContainer}>
                            {activateLoading ? (
                              <View style={styles.loadingStatus}>
                                <Text style={TextStyles.subtitle}>
                                  Processing, Please Wait...
                                </Text>
                              </View>
                            ) : null}
                            <Text style={TextStyles.subtitle}>Plan</Text>
                            <View>
                              <Text style={styles.label}>
                                Data: {selectedPlan?.data}
                              </Text>
                              <Text style={styles.label}>
                                Plan Fee: ${selectedPlan?.plan_fee}{" "}
                                {selectedPlan
                                  ? getTimeFromPlanTypeD(
                                      selectedPlan.planTypeD,
                                      selectedPlan.isOt
                                    )
                                  : ""}
                              </Text>
                            </View>
                            <AppGap direction="vertical" size={Sizes.large} />
                            <Text style={TextStyles.subtitle}>
                              Upfront Cost
                            </Text>
                            {province ? null : (
                              <Text style={{ color: Colors.red }}>
                                (Please select Province to get updated info)
                              </Text>
                            )}
                            <AppGap direction="vertical" size={Sizes.large} />
                            <View style={CommonStyles.justifyRow}>
                              <View>
                                <Text style={styles.label}>Service Charge</Text>
                                <Text style={styles.label}>
                                  Period:{" "}
                                  <Text style={{ color: Colors.grey }}>
                                    {prepaidTotalStrings.prorateDuration ===
                                    "N/A"
                                      ? prepaidTotalStrings.chargeDuration
                                      : prepaidTotalStrings.prorateDuration}
                                  </Text>
                                </Text>
                              </View>
                              <Text>
                                $
                                {prepaidTotalStrings.prorateDuration === "N/A"
                                  ? prepaidTotalStrings.planAmt
                                  : prepaidTotalStrings.prorateAmt}
                              </Text>
                            </View>
                            <View style={CommonStyles.justifyRow}>
                              <Text style={styles.label}>Others</Text>
                              <Text>${"0.00"}</Text>
                            </View>
                            <View style={CommonStyles.justifyRow}>
                              <Text style={styles.label}>Sub total</Text>
                              <Text>${prepaidTotalStrings.subtotal}</Text>
                            </View>
                            <View style={CommonStyles.justifyRow}>
                              <Text style={styles.label}>
                                Taxes ({prepaidTotalStrings.totalTaxRate}%)
                              </Text>
                              <Text>${prepaidTotalStrings.totalTaxAmt}</Text>
                            </View>
                            <AppGap direction="vertical" size={Sizes.small} />
                            <AppHorizontalBar
                              size={300}
                              color={Colors.lightGrey}
                            />
                            <AppGap direction="vertical" size={Sizes.small} />
                            <View style={CommonStyles.justifyRow}>
                              <Text style={styles.label}>Total Due</Text>
                              <Text>${prepaidTotalStrings.total}</Text>
                            </View>
                          </View>
                        </View>
                      </>
                    )}
                  </>
                )}
              </View>
            </View>
            <AppModal visible={modalVisible} onClose={handleCloseModal}>
              {step !== StepType.Success && (
                <View>
                  <View style={FlexStyles.rowCenter}>
                    <Text style={TextStyles.subtitle}>Plan Information</Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.large} />
                  <View style={FlexStyles.rowCenter}>
                    <Text>Please confirm the following information:</Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.large} />
                  <View style={FlexStyles.rowCenter}>
                    <Text>Your Service will start on:</Text>
                    <Text style={{ paddingLeft: 4 }}>{formattedStart}</Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.small} />
                  <View style={FlexStyles.rowCenter}>
                    <Text>
                      {termCategory === "ot"
                        ? "Your Service will end on:"
                        : "Your billing period end date:"}
                    </Text>
                    <Text style={{ paddingLeft: 4 }}>{formattedBillend}</Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.small} />
                  <View style={FlexStyles.rowCenter}>
                    <Text>Data: {selectedPlan?.data}</Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.small} />
                  <View style={FlexStyles.rowCenter}>
                    <Text>Price: ${selectedPlan?.plan_fee.toFixed(2)}</Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.small} />
                  <View style={FlexStyles.rowCenter}>
                    <Checkbox
                      value={acceptCorrectInformation}
                      onValueChange={() =>
                        setAcceptCorrectInformation(!acceptCorrectInformation)
                      }
                    />
                    <Text style={{ paddingLeft: 4 }}>
                      I accept that I have selected and filled in the correct
                      information.
                    </Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.large} />
                  <View style={FlexStyles.rowCenter}>
                    <AppButton
                      onPress={onPressActivate}
                      text="Activate"
                      disabled={!acceptCorrectInformation}
                      loading={activateLoading}
                    />
                  </View>
                  {activationError.length > 0 && (
                    <Text
                      style={{
                        color: Colors.red,
                        marginTop: Sizes.medium,
                        textAlign: "center",
                      }}
                    >
                      {activationError}
                    </Text>
                  )}
                </View>
              )}
              {step === StepType.Success && (
                <View>
                  <View style={FlexStyles.rowCenter}>
                    <Text style={TextStyles.subtitle}>Success!</Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.large} />
                  <View style={FlexStyles.rowCenter}>
                    <Text>
                      Your activation succeeded with ref # {activationRefNumber}
                      .
                    </Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.large} />
                  <View style={FlexStyles.rowCenter}>
                    <AppButton onPress={handleCloseModal} text="OK" />
                  </View>
                </View>
              )}
            </AppModal>
          </ScrollView>
          <AppFooter />
        </>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  planSelectCard: {
    width: 245,
    padding: 10,
    marginTop: 20,
    marginRight: 10,
    marginLeft: 10,
  },
  dataLabel: {
    color: Colors.blue,
    backgroundColor: Colors.lightBlue,
    padding: 10,
    borderRadius: 10,
    fontWeight: "600",
    fontSize: 20,
    fontFamily: FontFamilys.bold,
  },
  moneyLabel: {
    color: Colors.blue,
    fontSize: 40,
    fontFamily: FontFamilys.bold,
  },
  timeLabel: {
    color: Colors.blue,
    fontSize: 10,
    fontFamily: FontFamilys.bold,
    marginTop: 4,
  },
  currencyLabel: {
    color: Colors.white,
    backgroundColor: Colors.red,
    fontSize: 10,
    borderRadius: 10,
    width: 34,
    textAlign: "center",
    fontFamily: FontFamilys.bold,
  },
  dollarSignLabel: {
    color: Colors.blue,
    fontSize: 20,
    fontFamily: FontFamilys.bold,
    paddingBottom: 12,
  },
  regularSimButton: {
    borderRadius: 10,
    borderStyle: "solid",
    borderColor: Colors.blue,
    borderWidth: 1,
    padding: 5,
    width: 140,
  },
  regularSimText: {
    textAlign: "center",
    color: Colors.blue,
    fontFamily: FontFamilys.semiBold,
  },
  eSimButton: {
    borderRadius: 10,
    backgroundColor: Colors.blue,
    padding: 5,
    width: 50,
  },
  eSimText: {
    textAlign: "center",
    color: Colors.white,
    fontFamily: FontFamilys.semiBold,
  },
  inputContainer: {
    flex: 1,
  },
  dropdown: {
    borderRadius: 10,
    borderWidth: 0,
    backgroundColor: Colors.lightGrey,
    paddingHorizontal: 16,
    height: 40,
  },
  subheading: {
    fontSize: 16,
    fontFamily: FontFamilys.regular,
    marginVertical: Sizes.medium,
  },
  planTag: {
    borderRadius: 10,
    paddingHorizontal: 24,
    paddingVertical: 12,
    marginRight: 10,
    borderStyle: "solid",
    borderColor: Colors.blue,
    borderWidth: 1,
  },
  planTagText: {
    fontFamily: FontFamilys.semiBold,
    fontSize: 20,
  },
  inputLabel: {
    fontFamily: FontFamilys.semiBold,
    marginBottom: Sizes.small,
  },
  label: {
    fontFamily: FontFamilys.semiBold,
  },
  datePickerContainer: {
    marginVertical: 10,
    alignContent: "flex-start",
  },
  disabledTextInput: {
    height: 50,
    backgroundColor: Colors.lightGrey,
    width: "100%",
    borderRadius: 15,
    margin: "auto",
    fontSize: 15,
    fontFamily: FontFamilys.regular,
    padding: 15,
    color: Colors.black,
    fontWeight: "300",
  },
  costContainer: {
    width: 300,
    // @ts-ignore
    height: "min-content",
  },
  loadingStatus: {
    position: "absolute",
    // @ts-ignore
    top: "-20px",
    // @ts-ignore
    left: "-20px",
    // @ts-ignore
    width: "calc(100% + 40px)",
    // @ts-ignore
    height: "calc(100% + 40px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    borderRadius: 8,
    color: "#ffffff",
  },
});
