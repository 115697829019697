import { Month } from "../types/models/Month";
import { getYearsArrayFrom } from "../utilities/utilities";

export default {
  months: [
    { label: "JAN", value: "01" },
    { label: "FEB", value: "02" },
    { label: "MAR", value: "03" },
    { label: "APR", value: "04" },
    { label: "MAY", value: "05" },
    { label: "JUN", value: "06" },
    { label: "JUL", value: "07" },
    { label: "AUG", value: "08" },
    { label: "SEP", value: "09" },
    { label: "OCT", value: "10" },
    { label: "NOV", value: "11" },
    { label: "DEC", value: "12" },
  ] as Array<Month>,
  years: getYearsArrayFrom(2016),
};
